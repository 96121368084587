<template>
    <div>
        <v-row v-if="$parent.$parent.isin(['cap_evaluador', 'cap_participante'])">
            <v-col cols="12" v-if="index.has_new_certif">
                <v-alert prominent class="ma-0" type="success">
                    Hay nuevos certificados de cursos completados que puede retirar
                    <template v-slot:append>
                        <v-btn outlined class="subtitle-2" :to="{ name: 'dashboard_cert_list' }">
                            Ver certificados
                        </v-btn>
                    </template>
                </v-alert>
            </v-col>
            <v-col cols="12" sm="6">
                <DashCard title="Cursos pendientes" title-icon="folder" body-class="pa-0">
                    <EmptyCard class="ma-4" v-if="index.cursos_pendientes?.length < 1" text="No tiene cursos pendientes" icon="mood" />
                    <ListCursos v-else v-model="index.cursos_pendientes" />
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6">
                <DashCard title="Cursos completados" title-icon="folder" body-class="pa-0">
                    <EmptyCard class="ma-4" v-if="index.cursos_completados?.length < 1" text="No tiene cursos completados" icon="inbox" />
                    <ListCursos v-else v-model="index.cursos_completados" :read-only="true" color="green" :finalizados="true" />
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6">
                <DashCard title="Grupos en los que está inscrito" title-icon="group" body-class="pa-0">
                    <ListGrupos v-if="index.grupos_activos?.length > 0" v-model="index.grupos_activos" />
                    <EmptyCard v-else class="ma-4" text="Actualmente no ha sido añadido en ningún grupo" icon="group" />
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6">
                <DashCard title="Grupos en los que estuvo inscrito" title-icon="group" body-class="pa-0">
                    <ListGrupos v-if="index.grupos_inactivos?.length > 0" v-model="index.grupos_inactivos" :finalizados="true" />
                    <EmptyCard v-else class="ma-4" text="No registra grupos pasados" icon="group" />
                </DashCard>
            </v-col>
        </v-row>
        <div v-else-if="$parent.$parent.isin('supers')">
             <v-row>
                 <v-col cols="12" sm="12" lg="6">
                    <DashCard title="Capacitados actuales" title-icon="person_pin_circle">
                        <PersonasCounter v-model="reciente" />
                    </DashCard>
                </v-col>
                 <v-col cols="12" sm="12" lg="6">
                    <DashCard title="Mejor aprobación actual" title-icon="donut_large" body-class="pa-4">
                        <BestIndicators v-model="indicadores" />
                    </DashCard>
                </v-col>
            </v-row>
            <v-row>
                 <v-col cols="12" md="6" lg="6">
                    <DashCard title="Cursos activos por grupo de acceso" title-icon="star" body-class="pa-0">
                        <ListCursos v-model="activos" :details="true" :read-only="true" />
                    </DashCard>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                    <DashCard title="Cursos caducados" title-icon="error_outline" body-class="pa-0">
                        <CaducadosTable v-model="caducados" />
                    </DashCard>
                </v-col>
            </v-row> 
            <v-row >
                <v-col cols="12" sm="6" lg="12">
                    <DashCard title="Últimos meses" title-icon="insert_chart_outlined" body-class="pa-0">
                        <SimpleXYChart type="area" :series="[general_timeline]" :extra="graph_config" />
                    </DashCard>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" lg="12">
                    <DashCard title="Capacitados diarios por curso" title-icon="insert_chart_outlined" body-class="pa-0">
                        <GraficoCurso :items="index.cursos_select" ref="grafico_curso" @change="curso_changed" />
                    </DashCard>
                </v-col>
            </v-row> 
             <v-row>  
                <v-col cols="12" sm="12" md="6" lg="6">
                    <DashCard title="Ranking (Empresas)" title-icon="list" body-class="pa-0">
                        <div class="px-4 py-2">
                            <v-label>Ranking por registros seleccionados en gráfico</v-label>
                        </div>
                        <v-divider />
                        <Ranking :items="curso.ranking.empresas" view="empresas" />
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                    <DashCard title="Ranking (Gerencias)" title-icon="list" body-class="pa-0">
                        <div class="px-4 py-2">
                            <v-label>Ranking por registros seleccionados en gráfico</v-label>
                        </div>
                        <v-divider />
                        <Ranking :items="curso.ranking.gerencias" view="gerencias" />
                    </DashCard>
                </v-col>
            </v-row>
        </div>
        
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import SimpleCard from '@/shared/components/SimpleCard'
import EmptyCard from '@/shared/components/EmptyCard'
import HorizontalCircularProgress from '@/shared/components/HorizontalCircularProgress'
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import { axiosHelper } from '@/helpers'
import moment from 'moment'

import GraficoCurso from '@/educap/components/dashboard/GraficoCurso'
import PersonasCounter from '@/educap/components/dashboard/PersonasCounter'
import CaducadosTable from '@/educap/components/dashboard/CaducadosTable'
import BestIndicators from '@/educap/components/dashboard/BestIndicators'
import Ranking from '@/educap/components/dashboard/Ranking'

import ListCursos from '@/educap/components/dashboard/ListCursos'
import ListGrupos from '@/educap/components/dashboard/ListGrupos'

export default {
    components: {
        EmptyCard,
        DashCard,
        ListCursos,
        ListGrupos,
        CaducadosTable,
        BestIndicators,
        PersonasCounter,
        GraficoCurso,
        Ranking,
        SimpleCard,
        SimpleXYChart,
        HorizontalCircularProgress
    },
    computed: {
        reciente() {
            if(!this.index.panorama_general) return {};
            return this.index.panorama_general.reciente;
        },
        indicadores() {
            if(!this.index.panorama_general) return {};
            return this.index.panorama_general.indicadores;
        },
        activos() {
            if(!this.index.cursos_activos) return [];
            return this.index.cursos_activos;
        },
        caducados() {
            if(!this.index.cursos_invalidos) return [];
            return this.index.cursos_invalidos;
        },
        panorama_general() {
            if(!this.index) return null;
            return this.index.panorama_general;
        },
        general_timeline() {
            if(!this.panorama_general) return [];
            return this.index.panorama_general.timeline;
        }
    },
    data: () => ({
        index: {},
        curso: {
            ranking: {
                empresas: [],
                gerencias: []
            }
        },
        graph_config: {
            xaxis: {
                type: 'datetime',
                labels: {
                    formatter: (val) => moment(val).format("MMM YYYY")
                }
            },
            yaxis: {
                type: 'numeric',
                labels: {
                    formatter: (val) => Math.round(val)
                }
            }
        }
    }),
    methods: {
        curso_changed(curso) {
            console.log("curso:" + curso);
            if(curso == null) {
                this.curso = {
                    ranking: {
                        empresas: [],
                        gerencias: []
                    }
                }
                return;
            }
            this.curso = curso;
        }
    },
    mounted() {
        this.$parent.$parent.set_title("Dashboard", "dashboard");

       
        axiosHelper.get('edu/dashboard')
        .then(response => {
            this.index = response.data;
            this.$nextTick(() => {
                if (this.$refs.grafico_curso) {
                    this.$refs.grafico_curso.update_curso(response.data.cursos_select[0].id);
                }
            });
        });
    }
}
</script>